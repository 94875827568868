export const companiesCheatEmployees = [
    {
        title: "Cusnir Bau",
        search: ["cusnir bau", "Cusnir Bau", "CUSNIR BAU", "cusnirbau", "молдавский пидар", "Mолдавский пидар"],
        taxNumber: "8971881415"
    },
    /*{
        title: "Cusnir Bau",
        search: ["cusnir", "sdsf", "молдавский пидар"],
        taxNumber: "8971881415"
    },*/
];
